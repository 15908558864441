<template>
	<div style="padding:0 30px;">
		<el-form ref="selfInfoForm" :model="selfInfoForm" :rules="selfInfoRules" label-width="100px"
			class="demo-ruleForm" label-position="left">
			<el-form-item label="头像">
<!--				<el-avatar size="medium" src="imageUrl"></el-avatar>-->
        <div v-if="selfInfoForm.sex === '2'">
          <img src="@/assets/girl.png" alt="" style="width: 8%;height: 8%">
        </div>
        <div v-else>
          <img src="@/assets/boy.png" alt="" style="width: 8%;height: 8%">
        </div>
			</el-form-item>
			<el-form-item label="姓名" prop="username">
				<el-input v-model="selfInfoForm.username" style="width: 300px"></el-input>
			</el-form-item>
			<el-form-item label="邮箱" prop="email">
				<el-input v-model="selfInfoForm.email" style="width: 300px"></el-input>
			</el-form-item>
			<el-form-item label="手机号码" prop="userPhone">
				<el-input v-model="selfInfoForm.userPhone" style="width: 300px"></el-input>
			</el-form-item>
			<el-form-item label="性别" prop="sex">
				<el-select v-model="selfInfoForm.sex" placeholder="请选择活动区域" style="width: 300px">
					<el-option label="男" value="1"></el-option>
					<el-option label="女" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="年龄" prop="age">
				<el-input-number v-model="selfInfoForm.age"></el-input-number>
			</el-form-item>
			<!-- 			<el-form-item label="出生日期" prop="birthday">
				<el-date-picker type="date" placeholder="选择日期" v-model="selfInfoForm.birthday" style="width: 100%;">
				</el-date-picker>
			</el-form-item> -->
			<el-form-item>
				<el-button type="primary" @click="onSubmit('selfInfoForm')" size="mini">提交</el-button>
				<el-button size="mini">取消</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {changeSelfInfo} from "@/api/index.js"
	export default {
		props: {
			userInfo: {
				type: Object,
				default () {
					return {}
				}
			}
		},
		watch: {
			userInfo: {
				handler: function(n, o) {
					this.selfInfoForm = this.userInfo;
				},
				deep: true
			}
		},
		data() {
			var checkEmail = (rule, value, callback) => {
				const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
				if (!value) {
					return callback(new Error('邮箱不能为空'))
				}
				setTimeout(() => {
					if (mailReg.test(value)) {
						callback()
					} else {
						callback(new Error('请输入正确的邮箱格式'))
					}
				}, 100)
			};
			var checkPhone = (rule, value, callback) => {
				const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
				if (!value) {
					return callback(new Error('电话号码不能为空'))
				}
				setTimeout(() => {
					// Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
					// 所以在前面加了一个+实现隐式转换

					if (!Number.isInteger(+value)) {
						callback(new Error('请输入数字值'))
					} else {
						if (phoneReg.test(value)) {
							callback()
						} else {
							callback(new Error('电话号码格式不正确'))
						}
					}
				}, 100)
			};
			var checkAge = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('必填项不能为空'))
				}
				setTimeout(() => {
					// Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
					// 所以在前面加了一个+实现隐式转换

					if (value > 100) {
						callback(new Error('年龄不能大于100'))
					} else {
						callback()
					}
				}, 100)
			}
			return {
				selfInfoForm: {
					id:"",
					imageUrl: "",
					username: "小徐",
					email: "123123@qq.com",
					userPhone: "11311231123",
					sex: "1",
					age: 20,
					// birthday: new Date()
				},
				selfInfoRules: {
					// imageUrl: [{
					// 	required: true,
					// 	message: '请输入名字',
					// 	trigger: 'blur'
					// }],
					username: [{
							required: true,
							message: '请输入名字',
							trigger: 'blur'
						},
						{
							min: 2,
							message: '长度大于2个字符',
							trigger: 'blur'
						}
					],
					email: [{
						required: true,
						message: '请输入邮箱',
						trigger: 'blur'
					}, {
						validator: checkEmail,
						trigger: 'blur'
					}],
					userPhone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						validator: checkPhone,
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}],
					age: [{
						required: true,
						message: '请输入年龄',
						trigger: 'blur'
					}, {
						validator: checkAge,
						trigger: 'blur'
					}],
					// birthday: [{
					// 	type: 'date',
					// 	required: true,
					// 	message: '请选择时间',
					// 	trigger: 'change'
					// }],
				}
			}
		},
		methods: {
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$prompt('请输入密码', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
						}).then(({
							value
						}) => {
							this.selfInfoForm.password = value;
							this.selfInfoForm.loginAccount = localStorage.getItem("username")
              this.selfInfoForm.createTime =null
							// console.log(this.selfInfoForm)
							changeSelfInfo(this.selfInfoForm).then(res => {
								// console.log(res);
								if(res.code == "0000"){
									this.$message.success("修改成功")
									this.$emit("successChange")
								}
							})
							// this.$message({
							// 	type: 'success',
							// 	message: '你的邮箱是: ' + value
							// });
						}).catch(() => {
							this.$message({
								type: 'info',
								message: '取消输入'
							});
						});
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
