<template>
	<div style="padding:0 30px;">
		<el-form ref="changePassWordForm" :model="changePassWordForm" :rules="changePassWordRules" label-width="100px" class="demo-ruleForm" label-position="left">
			<el-form-item label="旧密码" prop="oldPassword">
				<el-input v-model="changePassWordForm.oldPassword" style="width: 300px"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="newPassword">
				<el-input v-model="changePassWordForm.newPassword" type="password"  style="width: 300px"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="surePassword">
				<el-input v-model="changePassWordForm.surePassword" type="password"  style="width: 300px"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit('changePassWordForm')" size="mini">提交</el-button>
				<el-button size="mini">取消</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {changePassword} from "@/api/index.js"
	export default {
		props:["userId"],
		data() {
			var checkSurePassWord = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请再次输入新密码'))
				}
				setTimeout(() => {
					if (value === this.changePassWordForm.newPassword) {
						callback()
					} else {
						callback(new Error('两次输入密码需要一致'))
					}
				}, 100)
			};
			return {
				changePassWordForm: {
					oldPassword: "",
					newPassword: "",
					surePassword: ""
				},
				changePassWordRules: {
					oldPassword: [{
							required: true,
							message: '请输入旧密码',
							trigger: 'blur'
						},
						{
							min: 4,
							message: '长度大于4个字符',
							trigger: 'blur'
						}
					],
					newPassword: [{
							required: true,
							message: '请输入新密码',
							trigger: 'blur'
						},
						{
							min: 4,
							message: '长度大于4个字符',
							trigger: 'blur'
						}
					],
					surePassword: [{
							required: true,
							message: '请再次输入新密码',
							trigger: 'blur'
						},
						{
							min: 4,
							message: '长度大于4个字符',
							trigger: 'blur'
						}, {
							validator: checkSurePassWord,
							trigger: 'blur'
						}
					],
				}
			}
		},
		methods: {
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(this.changePassWordForm)
						// let loginAccount = localStorage.getItem("username")
						let param = {
							id:this.userId,
							loginAccount:localStorage.getItem("username"),
							oldPassword:this.changePassWordForm.oldPassword,
							newPassword:this.changePassWordForm.newPassword
						}
						changePassword(param).then(res => {
							// console.log(res)
							if(res.code == "0000"){
								this.$router.push({path:"/"})
							}
						})
						// console.log(param)
						// alert('submit!');
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
