<template>
	<div style="padding: 0 30px;">
		<!-- <p style="font-size: 22px;font-weight: 900;line-height: 40px;">总积分:8888</p> -->
		<el-table :data="pointsDetailsData" stripe style="width: 100%;margin-top: 30px;" border height="600px">
			<el-table-column prop="remark" label="积分来源">
			</el-table-column>
			<el-table-column prop="integral" label="积分值">
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {
		pointDetails
	} from "@/api/index"
	export default {
		data() {
			return {
				pointsDetailsData: [{
					createTime: "2021-03-26 07:01:22",
					createUser: 2,
					id: 64,
					integral: 1,
					remark: "用户登录",
					status: 0,
					updateTime: "2021-03-26 07:01:22",
					updateUser: 2,
					userId: 2
				}]
			}
		},
		mounted() {
			this.getInfo()
		},
		methods: {
			getInfo() {
				pointDetails().then(res => {
					if (res.code === "0000") {
						this.pointsDetailsData = res.data
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
