<template>
  <div style="padding: 0 30px;">
    <el-collapse accordion>
      <el-collapse-item v-for="item in allMessage" :key="item.title">
        <template slot="title">
          <div style="display: flex;justify-content: space-between;width: 96%;margin-left: 3%;" @click="read(item.id)">
            <p style="font-size: 14px;">{{ item.title }}</p>
            <div v-if="item.readStatus === 1">
              <el-link icon="el-icon-check" type="success" :underline="false">已读</el-link>
            </div>
            <div v-else>
              <el-link icon="el-icon-view" type="danger" :underline="false">未读</el-link>
            </div>
          </div>
        </template>
        <div style="width: 96%;margin-left: 5%;">
          <div>内容：{{ item.content }}</div>
          <div>时间：{{ item.createTime }}</div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {
  getUserNotice,
  read
} from "@/api/index.js"

export default {
  data() {
    return {
      allMessage: []
    }
  },
  methods: {
    getInfo() {
      getUserNotice().then(res => {
        if (res.code === "0000") {
          this.allMessage = res.data.list
        }
      })
    },
    read(id) {
      read(id).then(res => {
        if (res.code === "0000") {
          getUserNotice().then(res => {
            if (res.code === "0000") {
              this.allMessage = res.data.list
            }
          })
        }
      })
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
