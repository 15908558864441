<template>
	<div class="page" v-loading="loading">
		<div style="margin-top: 30px;background-color: #FFFFFF;padding: 30px 0px;">
			<el-tabs tab-position="left" style="height: 700px">
				<el-tab-pane>
					<span slot="label"><i class="el-icon-user"></i> 个人信息</span>
					<personalInfo :userInfo="userInfo"></personalInfo>
				</el-tab-pane>
				<el-tab-pane>
					<span slot="label"><i class="el-icon-edit"></i> 更新信息</span>
					<changeInfo :userInfo="userInfo" @successChange="successChange"></changeInfo>
				</el-tab-pane>
				<el-tab-pane>
					<span slot="label"><i class="el-icon-unlock"></i> 修改密码</span>
					<changePassWord :userId="userInfo.id"></changePassWord>
				</el-tab-pane>
        <el-tab-pane>
          <span slot="label"><i class="el-icon-notebook-2"></i> 我的课程</span>
          <classDetails></classDetails>
        </el-tab-pane>
				<el-tab-pane>
					<span slot="label"><i class="el-icon-camera"></i> 个人动态</span>
					<personalNews></personalNews>
				</el-tab-pane>
				<el-tab-pane>
					<span slot="label"><i class="el-icon-star-off"></i> 积分明细</span>
					<pointsDetails></pointsDetails>
				</el-tab-pane>
				<el-tab-pane>
					<span slot="label"><i class="el-icon-message"></i> 消息中心</span>
					<messageCenter></messageCenter>
				</el-tab-pane>
			</el-tabs>
		</div>


	</div>
</template>

<script>
	import personalInfo from "./components/personalInfo.vue"
	import changeInfo from "./components/changeInfo.vue"
	import changePassWord from "./components/changePassWord.vue"
	import personalNews from "./components/personalNews.vue"
	import classDetails from "./components/classDetails.vue"
	import pointsDetails from "./components/pointsDetails.vue"
	import messageCenter from "./components/messageCenter.vue"
	import {selfInfo} from "@/api/index.js"
	export default {
		components: {
			personalInfo,
			changeInfo,
			changePassWord,
			personalNews,
      classDetails,
			pointsDetails,
			messageCenter
		},
		data() {
			return {
				userInfo: {},
				loading:false
			}
		},
		mounted() {
			this.getInfo()
		},
		methods: {

			getInfo() {
				this.loading = true;
				selfInfo().then(res => {
					// console.log(res)
					if (res.code === "0000") {
						this.userInfo = res.data
						this.loading = false;
					}
				});
			},
			successChange(){
				this.getInfo()
			}
		}
	}
	// };
</script>

<style scoped>
	/deep/.el-tabs__nav {
		padding: 0 30px;
	}

	/deep/.el-tabs__item {
		margin-top: 10px;
	}

	/deep/.el-tabs__item {
		text-align: center !important;
	}

	/deep/.el-tabs__item.is-active {
		color: #FFFFFF;
		background-color: #409EFF;
		border-radius: 50px;
	}

	/deep/.el-tabs__content {
		height: 600px;
		/*overflow-y: scroll;*/
	}

	.el-header {
		background-color: #B3C0D1;
		color: #333;
		line-height: 60px;
	}

	.el-aside {
		color: #333;
	}
</style>
