<template>
	<div style="padding:0 30px;">
		<div class="personNewsDetails">
			<div v-for="item in allMessage" :key="item.id">
				<el-alert :title="item.createTime" type="info" :description="item.content" show-icon>
				</el-alert>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getSelfLog
	} from "@/api/index.js"
	export default {
		data() {
			return {
				allMessage: []
			}
		},
		methods: {
			getInfo() {
				getSelfLog().then(res => {
					if (res.code == "0000") {
						this.allMessage = res.data
					}
				})
			}
		},
		mounted() {
			this.getInfo()
		}
	}
</script>

<style lang="scss" scoped>
	.personNewsDetails {
		.el-alert {
			margin-top: 10px;
		}
	}
</style>
