<template>
  <div style="padding: 0 30px;">
    <p style="font-size: 22px;font-weight: 400;line-height: 40px;text-align: center">我的课程</p>
    <el-table :data="classDetailsData" stripe style="width: 100%;margin-top: 30px;" border height="390px">
      <el-table-column
              type="index"
              width="50">
      </el-table-column>
      <el-table-column prop="classContent" style="text-align: center" width="400" label="课程名称">
      </el-table-column>
<!--      <el-table-column prop="course" label="课程类型">-->
<!--        <template slot-scope="scope">-->
<!--          <p v-if="scope.row.onlineClassInfo.course===0">Scratch</p>-->
<!--          <p v-else-if="scope.row.onlineClassInfo.course===1">C++</p>-->
<!--          <p v-else-if="scope.row.onlineClassInfo.course===2">Python</p>-->
<!--          <p v-else-if="scope.row.onlineClassInfo.course===3">NOIP</p>-->
<!--          <p v-else-if="scope.row.onlineClassInfo.course===4">机器人</p>-->
<!--          <p v-else>其他</p>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column prop="startDate" label="上课日期">
      </el-table-column>
      <el-table-column prop="classTime" label="上课时间">
      </el-table-column>
      <el-table-column prop="classState" label="课程状态">
        <template slot-scope="scope">
          <p v-if="scope.row.classState===0">预约过期</p>
          <p v-if="scope.row.classState===1">预约成功</p>
          <p v-if="scope.row.classState===2">学习中</p>
          <p v-if="scope.row.classState===3">本次课已结束</p>
            <!--            <el-button type="primary" size="mini" style="margin-left: 20px">立即支付</el-button>-->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  classDetails
} from "@/api/index"

export default {
  data() {
    return {
      classDetailsData: [{
        id: null,
        classTime: '',
        classState: '',
        createTime: "",
        onlineClassInfo: {
          course: '',
          title: ''
        }
      }]
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      classDetails().then(res => {
        if (res.code === "0000") {
          this.classDetailsData = res.data.list
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
