<template>
  <div style="padding:0 30px;">
    <div class="oneInfoDetail">
      <p>头像:</p>
      <!--				<el-avatar size="medium" src="imageUrl"></el-avatar>-->
      <span>
      <div v-if="userInfo.sex === '2'">
        <img src="@/assets/girl.png" alt="" style="width: 20%;height: 12%">
      </div>
      <div v-else>
        <img src="@/assets/boy.png" alt="" style="width: 20%;height: 20%">
      </div>
      </span>
    </div>
    <div class="oneInfoDetail">
      <p>登录账号:</p><span>{{ userInfo.loginAccount }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>myCode:</p><span>{{ userInfo.icode }}</span>
    </div>
    <!--		<div class="oneInfoDetail">-->
    <!--			<p>用户名:</p><span>{{userInfo.nickname}}</span>-->
    <!--		</div>-->
    <div class="oneInfoDetail">
      <p>用户名:</p><span>{{ userInfo.username }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>邮箱:</p><span>{{ userInfo.email }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>手机号:</p><span>{{ userInfo.userPhone }}</span>
    </div>

    <div class="oneInfoDetail">
      <p>性别:</p><span>{{ userInfo.sex | formatSex }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>年龄:</p><span>{{ userInfo.age }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>用户类型:</p>
      <span v-if="userInfo.teacherFlag === '1' " style="color: red">教师版会员</span>
      <span v-else>{{ userInfo.userVipType | formatUserVipType }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>会员生效时间:</p><span>{{ userInfo.startDate }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>会员失效时间:</p><span>{{ userInfo.expiryDate }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>注册时间:</p><span>{{ userInfo.createTime }}</span>
    </div>
    <div class="oneInfoDetail">
      <p>积分:</p><span>{{ userInfo.integral }}</span>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    formatSex(sex) {
      if (sex == 1) {
        return "男"
      }
      if (sex == 2) {
        return "女"
      }
    },
    formatUserVipType(userVipType) {
      if (userVipType === '0') {
        return "普通用户"
      }
      if (userVipType === '1') {
        return "VIP会员-终身"
      }
      if (userVipType === '2') {
        return "超级会员"
      }
      if (userVipType === '3') {
        return "体验会员"
      }
      if (userVipType === '4') {
        return "VIP会员"
      }
      if (userVipType === '5') {
        return "SVIP会员"
      }else{
        return "普通用户"
      }
    }
  },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.oneInfoDetail {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  height: 50px;

  p {
    line-height: 60px;
    height: 60px;
    font-size: 16px;
    font-weight: 800;
    width: 130px;
  }

  span {
    line-height: 60px;
    height: 60px;
    font-size: 16px;
  }
}
</style>
